<template>
  <v-img
    src="@/assets/bg_register_volunteer.png"
    lazy-src="@/assets/bg_register_volunteer_small.jpg"
    height="100vh"
    width="100%"
    class="bg-cover"
  >
    <v-container
      fluid
      class="d-flex flex-column justify-center align-center"
      style="height: 100%"
      :class="$vuetify.breakpoint.xs ? 'pa-0' : ''"
    >
      <v-card
        class="justify-center overflow-auto"
        max-width="600"
        color="white"
      >
        <v-card-title
          class="d-flex align-center justify-center mt-3"
          style="color: #0A5B8A"
        >
          Register as a Volunteer
        </v-card-title>
        <v-card-title class="mx-3">
          <v-row>
            <v-col cols="6" class="py-0">
              <v-text-field
                v-model="first_name"
                label="First name*"
                dense
                filled
                background-color="white"
                @input="onChange"
                outlined
                :rules="[rules.required]"
              />
            </v-col>
            <v-col cols="6" class="py-0">
              <v-text-field
                v-model="last_name"
                label="Last name*"
                dense
                filled
                background-color="white"
                @input="onChange"
                outlined
                :rules="[rules.required]"
              />
            </v-col>
            <v-col :cols="$vuetify.breakpoint.xs ? 12 : 6" class="py-0">
              <v-text-field
                v-model="email"
                label="Email address*"
                dense
                filled
                background-color="white"
                @input="onChange"
                outlined
                :rules="[rules.required, rules.email]"
                :error-messages="emailErrorMessage"
              />
            </v-col>
            <v-col :cols="$vuetify.breakpoint.xs ? 12 : 6" class="py-0 mb-5">
                <AppPhoneNumberInput
                v-model="phone"
                @input="onChange"
                @update="onUpdatePhone"
                :error="!!phoneErrorMessage"
                :error-messages="phoneErrorMessage"
                :initial-value="1"
                />
            </v-col>
            <v-col cols="12" class="py-0">
              <v-select
                :items="ageOptions"
                v-model="age_group"
                label="Age*"
                flat
                outlined
                dense
                @input="onChange"
                :rules="[rules.required]"
              />
            </v-col>
            <v-col cols="12" class="py-0">
              <v-text-field
                v-model="address"
                label="Address*"
                dense
                filled
                background-color="white"
                @input="onChange"
                outlined
                :rules="[rules.required, rules.address]"
              />
            </v-col>
            <v-col cols="12" class="py-0">
              <v-text-field
                v-model="address2"
                label="Address 2"
                dense
                filled
                background-color="white"
                @input="onChange"
                outlined
              />
            </v-col>
            <v-col :cols="$vuetify.breakpoint.xs ? 12 : 4" class="py-0">
              <v-text-field
                v-model="city"
                label="City*"
                dense
                filled
                background-color="white"
                @input="onChange"
                outlined
                :rules="[rules.required, rules.city]"
              />
            </v-col>
            <v-col :cols="$vuetify.breakpoint.xs ? 12 : 4" class="py-0">
              <v-select
                v-model="state"
                label="State*"
                dense
                filled
                background-color="white"
                @input="onChange"
                outlined
                :items="stateOptions"
                :rules="[rules.required, rules.state]"
              />
            </v-col>
            <v-col :cols="$vuetify.breakpoint.xs ? 12 : 4" class="py-0">
              <v-text-field
                v-model="zip"
                label="Zip code*"
                dense
                filled
                background-color="white"
                @input="onChange"
                outlined
                :rules="[rules.required, rules.zip]"
              />
            </v-col>
            <v-col cols="12" class="py-0">
              <v-text-field
                v-model="password"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[rules.required, rules.min]"
                :type="showPassword ? 'text' : 'password'"
                @click:append="showPassword = !showPassword"
                @input="onChange"
                label="Password*"
                dense
                filled
                outlined
                background-color="white"
                class="pb-0 mb-0"
                full-width
                style="width: 100%"
              />
            </v-col>
            <v-col cols="12" class="py-0">
              <v-text-field
                v-model="confirmPassword"
                :append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[rules.required, rules.min]"
                :type="showConfirmPassword ? 'text' : 'password'"
                @click:append="showConfirmPassword = !showConfirmPassword"
                @input="onChange"
                label="Confirm password*"
                dense
                filled
                outlined
                background-color="white"
                class="pb-0 mb-0"
                full-width
                style="width: 100%"
              />
            </v-col>
          </v-row>
          <div style="width: 100%">
            <!-- <v-btn
              rounded
              color="#7024c4"
              width="100%"
              @click="onSignUp"
              :loading="loading"
              class="mt-5 white--text"
              >Register</v-btn
            > -->
            <v-btn
              v-if="!phoneErrorMessage" 
              rounded
              color="#7024c4"
              width="100%"
              @click="onSignUp"
              :loading="loading"
              class="mt-5 white--text"
            >
              <template v-if="loading">
              <v-progress-circular
                indeterminate
                color="white"
                size="20"
              ></v-progress-circular>
              </template>
              <template v-else>
              Join
              </template>
            </v-btn>

            <v-btn
              v-else 
              rounded
              color="red"
              width="100%"
              :disabled="true" 
              class="mt-5 white--text"
            >
              {{ phoneErrorMessage }}
            </v-btn>
            <v-btn
              text
              class="text-none mb-4 mt-4"
              block
              rounded
              @click="onBack()"
              >Back to login</v-btn
            >
          </div>
        </v-card-title>
      </v-card>
      <v-snackbar v-model="snackbar">
        {{ errorMessage }}
      </v-snackbar>
    </v-container>
  </v-img>
</template>
<script>
import { mapActions } from "vuex";
import AppPhoneNumberInput from "../../components/common/AppPhoneNumberInput.vue";

export default {
  components: { AppPhoneNumberInput },
  data() {
    return {
      first_name: "",
      last_name: "",
      email: "",
      password: "",
      confirmPassword: "",
      loading: false,
      snackbar: false,
      errorMessage: null,
      city: "",
      zip: "",
      state: "",
      address: "",
      address2: "",
      age_group: "",
      phoneErrorMessage: "",
      rules: {
        required: value => !!value || "Required.",
        min: v => (v && v.length >= 8) || "Min 8 characters",
        // email: value => {
        //   const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(?<!\.)$/;
        //   return pattern.test(value) &&
        //     !/(\.\.|@\.|@-|-\.|_\.|,|#|\s)/.test(value) &&
        //     !/^[^@]+@[^@]+\.[^@]+$/.test(value) &&
        //     !value.endsWith('.') &&
        //     !value.startsWith('@') &&
        //     !value.includes('..')
        //     ? true
        //     : "Invalid email address.";
        // },
               email: value => {
          const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/;
          return pattern.test(value) || "Invalid email address.";
        },
        phone: value => {
          const pattern = /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;
          return pattern.test(value) || "Invalid phone number.";
        },
        address: value => value.trim().length > 5 || "Address must be at least 6 characters.",
        city: value => /^[a-zA-Z\s]+$/.test(value) || "City must contain only letters.",
        state: value => this.stateOptions.includes(value) || "Invalid state selected.",
        zip: value => /^\d{5}(-\d{4})?$/.test(value) || "Invalid zip code.",
        passwordMatch: () => 
          this.password === this.confirmPassword || "Passwords do not match."
      },
      showPassword: false,
      showConfirmPassword: false,
      emailErrorMessage: null,
      phone: "",
      phonePayload: {},
      ageOptions: [
        "13-18",
        "19-24",
        "25-34",
        "35-44",
        "45-54",
        "55-64",
        "65 and older"
      ],
      stateOptions: [
        "AL",
        "AK",
        "AZ",
        "AR",
        "CA",
        "CO",
        "CT",
        "DE",
        "FL",
        "GA",
        "HI",
        "ID",
        "IL",
        "IN",
        "IA",
        "KS",
        "KY",
        "LA",
        "ME",
        "MD",
        "MA",
        "MI",
        "MN",
        "MS",
        "MO",
        "MT",
        "NE",
        "NV",
        "NH",
        "NJ",
        "NM",
        "NY",
        "NC",
        "ND",
        "OH",
        "OK",
        "OR",
        "PA",
        "RI",
        "SC",
        "SD",
        "TN",
        "TX",
        "UT",
        "VT",
        "VA",
        "WA",
        "WV",
        "WI",
        "WY",
        "AS",
        "DC",
        "FM",
        "GU",
        "MH",
        "MP",
        "PW",
        "PR",
        "VI"
      ]
    };
  },
  methods: {
    ...mapActions("auth", {
      registerVolunteer: "onRegisterVolunteer",
      checkEmail : "onCheckEmail",
      checkPhone: "onCheckPhone"
    }),
  onChange() {
      this.snackbar = false;
      this.errorMessage = null;
      if (this.email) {
        this.checkEmailAddress();
      }
      if (this.phone) {
        this.checkPhoneNumber();
      }
    },
    checkEmailAddress() {
      let params = {
        email: this.email
      };
      this.checkEmail(params)
        .then(() => {
          this.loading = false;
           this.emailErrorMessage = "";
        })
        .catch(error => {
          this.loading = false;
          if (error.response == undefined) {
            console.log(error);
          } else {
            this.showSnackbar(error.response.data.message);
            this.emailErrorMessage = error.response.data.message;
          }
        });
    },
    checkPhoneNumber() {
      let params = {
        phone: this.phone
      };
      this.checkPhone(params)
        .then(() => {
          this.loading = false;
          this.phoneErrorMessage = "";
        })
        .catch(error => {
          this.loading = false;
          if (error.response == undefined) {
            console.log(error);
          } else {
            this.phoneErrorMessage = error.response.data.message;
          }
        });
    },
    onUpdatePhone(payload) {
      this.phonePayload = payload;
    },
    showSnackbar(message) {
      this.snackbar = true;
      this.errorMessage = message;
    },
    onBack() {
      this.$router.push({ name: "login" });
    },
    onSignUp() {
      if (!this.first_name) {
        this.showSnackbar("First name is required");
        return;
      }
      if (!this.last_name) {
        this.showSnackbar("Last name is required");
        return;
      }
      if (!this.email) {
        this.showSnackbar("Email is required");
        return;
      }
      if (!this.phone) {
        this.showSnackbar("Phone is required");
        return;
      }
      if (!this.age_group) {
        this.showSnackbar("Age is required");
        return;
      }
      if (!this.address) {
        this.showSnackbar("Address is required");
        return;
      }
      if (!this.city) {
        this.showSnackbar("City is required");
        return;
      }
      if (!this.state) {
        this.showSnackbar("state is required");
        return;
      }
      if (!this.zip) {
        this.showSnackbar("Zip Code is required");
        return;
      }
      if (!this.password) {
        this.showSnackbar("Password is required");
        return;
      }
      if (!this.confirmPassword) {
        this.showSnackbar("Confirm Password");
        return;
      }
      if (!this.phonePayload.isValid) {
        this.showSnackbar("Invalid phone");
        return;
      }
      if (this.rules.email(this.email) != true) {
        this.showSnackbar("Invalid email");
        return;
      }
      if (this.password.length < 8) {
        this.showSnackbar("Password required more than 8 letters");
        return;
      }
      if (this.password != this.confirmPassword) {
        this.showSnackbar("Password does not match");
        return;
      }
      this.loading = true;
      let phone = this.phone;
      if (
        this.phonePayload.isValid &&
        this.phonePayload.countryCallingCode != "1"
      ) {
        phone = `+${this.phonePayload.countryCallingCode} ${phone}`;
      }
      this.registerVolunteer({
        first_name: this.first_name,
        last_name: this.last_name,
        email: this.email,
        phone,
        age_group: this.age_group,
        address: this.address,
        address2: this.address2,
        city: this.city,
        state: this.state,
        zip: this.zip,
        password: this.password
      })
        .then(() => {
          this.loading = false;
          this.$router.push({
            name: "volunteer-home",
            query: { welcome: true }
          });
        })
        .catch(error => {
          this.loading = false;
          if (error.response == undefined) {
            console.log(error);
          } else {
            this.showSnackbar(error.response.data.message);
          }
        });
    }
  }
};
</script>
<style scoped>
.bg-cover :deep(.v-image__image) {
  filter: blur(8px);
  -webkit-filter: blur(8px);
}
</style>
